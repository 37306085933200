<template>
  <!--Navbar Start-->
  <nav
    class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark"
    id="navbar"
    :class="{'navbar-light': navbarcolor === true}"
  >
    <div class="container">
      <!-- LOGO -->
      <div class="navbar-brand logo">
        <h1 style="color: #000;"><img src="@/assets/images/logodark.png" alt="AutoCallOne" style="max-width:250px; width: auto; position: relative;"></h1>
      </div>

        <ul class="navbar-nav navbar-center">
          <li class="nav-item">
            <b-dropdown-item v-b-toggle.sidebar>Support & FAQ</b-dropdown-item>
          </li>
          <li class="nav-item" @click="saySomething">
            <a href="javascript: void(0);" class="nav-link" v-b-modal.login-modal>Logout</a>
          </li>
        </ul>
      </div>
    </div>




  </nav>
  <!-- Navbar End -->
</template>



<script>
import firebase from 'firebase'

export default {
  props: {
    navbarcolor: {
      type: Boolean,
      default: false
    }
  },
  mounted: () => {
    window.onscroll = function() {
      onwindowScroll();
    };
    var navbar = document.getElementById("navbar");
    function onwindowScroll() {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        navbar.classList.add("nav-sticky");
      } else {
        navbar.classList.remove("nav-sticky");
      }
    }   
  },
  methods: {
        saySomething()
        {
            firebase.auth().signOut()
            .then((resp)=>{
                window.location.href = 'https://AutoCall.One';
            })
            .catch((error)=>{
                console.log(error)
            })
        }


  }
};
</script>