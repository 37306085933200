<script>
import {
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  LinkedinIcon
} from "vue-feather-icons";
export default {
  components: {
    FacebookIcon,
    TwitterIcon,
    InstagramIcon,
    LinkedinIcon
  }
};
</script>

<template>
  <div>
    <!-- Footer Start -->
    <section class="footer" style="background-image: url(assets/images/footer-bg.png)">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="mb-5">
              <p class="text-white-50 my-4 font-size-15">
                AutoCallOne is an automated calling system to get you through to a hold line with your states unemployment office. We do our best to beat the pricing of other calling services by keeping our margins small to help keep the service affordable while covering our overhead.</p>
            </div>
          </div>

        </div>
      </div>
    </section>
    <div class="footer-alt py-3">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p
                class="text-white-50 font-size-15 mb-0"
              >{{ new Date().getFullYear()}} © AutoCallOne</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer End -->
  </div>
</template>