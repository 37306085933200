<template>
  <div>
    <Navbar />
    
    <div v-scroll-spy>
      <!-- Hero Start -->
      <section
        class="hero-1-bg"
        :style="{'background-image': 'url(' + require('@/assets/images/hero-1-bg-img.png') + ')'}"
        id="home"
      >


      <div class="container">
      

        <div>
          <h1 style="color:black;text-align: center; ">Dashboard</h1>
        </div>
        <div>
          <div v-if="account">
            <b-card
              title="Profile"
              img-top
              tag="article"
              class="mb-8"
            >
            <b-card-text><b>User Phone Number:</b> {{number}} </b-card-text>
            <b-card-text><b>User ID:</b> {{uid}} </b-card-text>
            <a :href="fbdata.receiptURL" target="_blank">View Last Receipt</a>
              </b-card>
            </div>
        </div>
        <br>
          <div v-if="fbdata.callStatus  === 'Done'" class="border">

            <h4 class="text-center" style="padding: 15px;">Buy Calling Service</h4>
                    <div class="row justify-content-center">
                      <div class="col-lg-8">
                        <div id="sq-ccbox">
                          <form id="payment-form">
                              <div id="card-container"></div>
                          </form>
                                    <div id="payment-status-container"></div>
                          <b-form-select id="options" v-model="selected" :options="options" style="height: 3em;"></b-form-select>
                        
                        </div>
                        
                      </div>
                      

                    </div>  
                    <br>
                    <div class="row justify-content-center" >
                          <button v-show='paymentSubmit' :disabled="selected == null" @click="requestCardNonce($event)" class="btn btn-primary pr-btn">Pay ${{cost}}</button>
                    </div>


          <br>
          <br>
            <div class="justify-content-center" style="padding: 15px;">
                <b-alert show>Once payment is submitted you will be presented with a "Start Call" button. When clicked, the program will start calling until we get a hold line. You will be called after the program detects the hold line. Current estimated wait time is 15-60 minutes for the entire process to speak with a live representative.</b-alert>
                <b-alert v-if="selected === 'capfl'" show variant="danger">The California Paid Family Leave line cannot keep up with the demand. The amount of calls let through are extremely limited so please expect a long wait time before you are called.</b-alert>
              </div>   
          </div>
            <div v-else-if="fbdata.callStatus  === 'Ready to Call'">
              <b-card
                title="Call Status"
                img-top
                tag="article"
                class="mb-8"
              >
                  <button @click="placeCall()" class="btn btn-primary pr-btn">Start Call</button>
                  <br>
                  <br>
                  <b-card-text>If you start the call outside our calling hours it will wait until the next day, and start calling automatically when office hours start. Calls after the listed time are deferred until the next day because their calling center will drop your call while you are on hold if they close. </b-card-text>
                  <b-card-text>Once your call successfully gets through to be placed on hold, you will receive a call from a <b>442</b> or <b>559</b> area code.</b-card-text>
                  <h4>Calling Hours:</h4>
                  <table v-for="stateInfo in lines" v-if="fbdata.state  === stateInfo.id" class="table striped hover">
                    <tr>
                      <th>Call Option</th>
                      <th>Number</th>
                      <th>Open</th>
                      <th>Close</th>
                      <th>Time Zone</th>
                    </tr>
                    <tr>
                      <td>{{ stateInfo.name }}</td>
                      <td>{{ stateInfo.phoneNumber }}</td>
                      <td>{{ stateInfo.hours['open'] }}</td>
                      <td>{{ stateInfo.hours['close'] }}</td>
                      <td>{{ stateInfo.hours['time_zone'] }}</td>
                    </tr>
                  </table>


                  <b-alert v-if="fbdata.state  === 'capfl'" show variant="danger">The California Paid Family Leave line cannot keep up with the demand. The amount of calls let through are extremely limited so please expect long wait time before you are called. We still guarantee we will get you through, or you'll receive your money back.</b-alert>
              </b-card>
              
            </div>
            <div v-else>
              <b-card
                title="Call Status"
                img-top
                tag="article"
                class="mb-8"
              >
                <status-indicator status="positive" pulse="true"/> {{fbdata.callStatus}} 
                <br>
                <br>
                <b-card-text>Once your call successfully gets through to be placed on hold, you will receive a call from a <b>442</b> or <b>559</b> area code. Your dashboard will always update to show the call status, but you are not required to stay on this page to receive a call.</b-card-text>
              </b-card>
            </div>


          

    <b-sidebar id="sidebar" title="Support and FAQ" shadow>
    <div class="px-3 py-2">
      <div class="input-group mb-3">
  <div class="input-group-prepend">
    <span class="input-group-text" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fill-rule="evenodd" d="M11.5 7a4.499 4.499 0 11-8.998 0A4.499 4.499 0 0111.5 7zm-.82 4.74a6 6 0 111.06-1.06l3.04 3.04a.75.75 0 11-1.06 1.06l-3.04-3.04z"></path></svg></span>
  </div>
  <input type="text" class="form-control" v-model="searchQuery" placeholder="Search..." aria-label="Search" aria-describedby="basic-addon1">
</div>
    </div>
      <div class="px-3 py-2" v-for="post of resultQuery">
        <h4>{{post.title}}</h4>
        <p><div v-html="post.body"></div></p>
      </div>
    </b-sidebar>
            

            
          
      </div>
  </section>


      <Footer />
    </div>
      <b-modal id="login-modal" ref=modal hide-header hide-footer centered no-close-on-backdrop>
            <div class="text-center">
                  <div class="navbar-brand logo">
                    <h1 style="color: #000;"><img src="@/assets/images/logodark.png" alt="AutoCallOne" style="max-width:200px; width: auto; position: relative; padding-bottom: 10px;"></h1>
                  </div>
              <h5 class="text-uppercase text-primary">
                <b>Sign-in or Register With Your Mobile Phone Number</b>
              </h5>
              <small>We send a text message to verify you.</small>
            </div>
            
      <div class="login-form mt-4">
        <form @submit.prevent="submit" v-if="!smsSent">
          <div class="form-group">
                <vue-tel-input v-model="phoneNumber" v-bind="bindProps"></vue-tel-input>
          </div>

          <div id="recaptcha-container" style="width:300px;margin:auto;"></div>

          <div class="text-center mt-4">
          
          <button class="btn btn-primary" id="log-in" type="submit">
              Next
            </button>
          </div>
        </form>

        <div v-if="smsSent" style="width:300px;height:180px;margin:auto; border-radius:10px;" class="text-center mt-4">
            <br>
            <div>
                <input type="text" 
                id="otpField"
                style="text-align:center;"
                pattern="[0-9]{6}"
                maxlength="6"
                v-model="otpnum"
                placeholder="Enter Code"
                required>
            </div>
            <br>
            <div>
                <button class="btn btn-primary" @click="verifyCode" id="otp-btn">Confirm</button>
            </div>
        </div>



      </div>
    </b-modal>
  </div>
</template>


<script>

  import firebase from "firebase";

  import Navbar from "@/components/dashnav";
  import Footer from "@/components/footer";
  import axios from 'axios';


export default {
  name: "paymentForm",
  data() {
    return {
      searchQuery: null,
      postList: [
        {title: 'Getting Started', body: 'To get started you have to prepay for a call. We use SquareUp Payments to provide secure payments on our platform. We designed an automated system that makes several simultaneous calls, and once the first line gets through to a hold line we call you and connect the lines.'},
        {title: 'What Happens If My Call Drops?', body: 'If the hold line is dropped or your representative hangs up on you, reach out by chat on the website or by emailing support@AutoCall.One and we will reset your account for another call. Sometimes the Paid Family Leave line will go blank, so you can hang up and reach out to us to start another call.'},
        {title: 'Why Are The Calling Hours Different?', body: 'Calls after the listed time are deferred until the next day because their calling center will drop your call while you are on hold if they close. If you start the call outside our calling hours it will wait until the next day, and start calling automatically when office hours start.'},
        {title: 'What If I cannot Answer when Called?', body: 'Our system will try to call you several times while you are on hold, but if we are not able to reach you before the representative hangs up your account will automatically reset and you can start the call when you are available.'},
        {title: 'What Are Dedicated Calls vs. Joining?', body: 'The two basic types of calls are ones you can join and dedicated calls that you can start from scratch. There are benefits to both. Dedicated calls have the possibility of receiving callbacks, and have a lower cost. Joining an already established call will have less wait time based on how far along the call is in the queue.'},
        {title: 'What Determines Pricing?', body: 'The primary cost is directly associated with the resources it takes to get through to a hold line using VoIP calling systems and STT software. It can take hours of calls to get through to a line during peak hours and we are always striving to reduce costs, which will be passed off to our customers.'},
        {title: 'What Are Estimated Wait Times?', body: 'Estimated wait times refers to the amount of time that it will take to get a hold line. We determine the wait time from the estimates given by Google and recent calls of that day, but do not guarantee them to be accurate.'},
        {title: 'Are My Calls Recorded?', body: 'Absolutely not. We use TTS (text to speech) technology for about 2-3 minutes of each phone call to grab wait times and call status, but it is discontinued before we join calls.'},
        {title: 'Are My Calls Secure?', body: 'Our software engineer comes from a strong I.T. security background. We only use enterprise databases, authentication and VoIP services. If you still have concerns about our services you should reach out to us to discuss by phone or video call.'},
        {title: 'Contacting Us', body: 'The fastest way to contact us is by chat at the bottom left of your screen. We can also be reached by email at support@AutoCall.One'},
        {title: '', body: '<br><br><br><br><br>'},
      ],
      card: null,
      paymentSubmit: true,
      selected: null,
      options: [],
      isActiveOT: false,
      isActiveLT: false,
      number: null,
      uid: null,
      user: null,
      account: null,
      id: null,
      cost: 9.99,
      fbdata: {
          callStatus: "Done",

      },
      errors: [],
      masterpass: false,
      applePay: false,
      phoneNumber:null,
      bindProps: {
        mode: "international",
        defaultCountry: "US",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: "Enter your phone number",
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        onlyCountries: ["US"],
        ignoredCountries: [],
        autocomplete: "off",
        name: "telephone",
        maxLen: 25,
        wrapperClasses: "",
        inputClasses: "",
        dropdownOptions: {
          disabled: false,
          showFlags: true,
          disabledDialCode: false
        },
        inputOptions: {
          showDialCode: false
        }
      }, 
      confirmationResult:null,
      number:null,
      otpnum:null,
      recaptchaVerifier:null,
      recaptchaWidgetId:null,
      confirmResult:null,
      smsSent:false,
      lines: [],
      caitems: [],
    };
  },
  async mounted() {
    let locationId = "KNBC0RXYPYK46";
    let appId = "sq0idp-6kqCeryaZPjeQPalLiyq4A";
    let that = this;

    const payments = Square.payments(appId, locationId)
    this.card = await payments.card();
    await this.card.attach('#card-container');


    ////////////////////////ONLY RUN IF POPUP SHOWS BECAUSE THIS ERRORS AND KILLS REST OF MOUNTED
    if (this.uid == null) {
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('log-in',{
            'size':'invisible',
            'callback':(response) => {
            //reCAPTCHA solved, allow signInWithPhoneNumber.
            console.log(response)
            }
      })
    }




    //console.log(card)

  },
  methods: {
    submit()
        {
            this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container')
            this.recaptchaVerifier.render().then((widgetId)=>{
            this.recaptchaWidgetId = widgetId    
            })
            
            var number = this.phoneNumber            
            firebase.auth().signInWithPhoneNumber(number,this.recaptchaVerifier)
            .then((confirmationResult)=>{                
                this.confirmResult = confirmationResult
                console.log(this.confirmResult)
                this.smsSent=true
            })
            .catch((error)=>{
                console.log("Sms not sent",error.message)
            })
        },
        verifyCode()
        {            
            this.confirmResult.confirm(this.otpnum)
            .then((result)=>{

                //this.$router.replace({name:"Dashboard"})
                var user = result.user
                firebase.auth().onAuthStateChanged((user) => {
                  if (user) {
                      this.$refs["modal"].hide();
                        this.number = user.phoneNumber
                        this.uid = user.uid
                        this.account = user
                        console.log(user)
                        firebase.firestore().collection("accounts").doc(user.uid).onSnapshot(querySnapshot => {
                          if (querySnapshot.exists) {  
                            console.log(querySnapshot.data())
                            this.fbdata = querySnapshot.data()
                          } else {
                            console.log("cant get user data")
                          }
                        });
                  } else {
                        console.log('No user')
                        this.user = null
                        this.$refs["modal"].show();
                  }
                });

                console.log(user)                
            })
            .catch((error)=>{
                console.log(error)
            })
        },
    onSubmit(){
            //this doesnt work because the submit is turned off in the callback
            //this.paymentSubmit = true;
    },
    selectOption() {
      //document.getElementById("lifetime").classList.add('btn btn-outline-primary pr-btn');
      
    },
    //showPaymentForm() {
    //  this.paymentForm.build();
      
    //},
    async requestCardNonce(event) {
      
      event.preventDefault();


      try {

        const result = await this.card.tokenize();

        if (result.status === 'OK') {

          console.log(`Payment token is ${result.token}`);

          console.log(document.getElementById("options").value)
          //can swap document.* with that.*
          axios.get('https://api.eddcaller.com/', { params: {nonce: `${result.token}`, uid: this.uid, amount: '9.99', state: document.getElementById("options").value} }).then(response => {
            console.log(response.data)
            if (response.data == "COMPLETED") {
              gtag('event', 'purchase', {'send_to': 'G-68Y1P7VEY0', 'transaction_id': nonce});
              this.$swal({
                position: 'top-end',
                icon: 'success',
                title: 'Payment Successful!',
                showConfirmButton: false,
                timer: 1500
              })
              fbq('track', 'purchase', {currency: "USD", value: 9.99});
              twq('track','purchase');
            } else {
                  this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Payment Unsuccessful. Please check your infmormation.',
                    showConfirmButton: false,
                    timer: 1500
                  })
            }
          })

        }

      } catch (e) {

        console.error(e);

      }

      this.paymentSubmit = false;  
      setTimeout(() => this.paymentSubmit = true, 8000)
    },
    onComplete() {
    },
    placeCall() {
      axios.get('https://api.eddcaller.com/startcall', { params: {uid:  this.uid, phone: this.number} });
    }

  },
  created()
  {

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
            this.number = user.phoneNumber
            this.uid = user.uid
            this.account = user
            console.log(user)
            firebase.firestore().collection("accounts").doc(user.uid).onSnapshot(querySnapshot => {
              if (querySnapshot.exists) {  
                console.log(querySnapshot.data())
                this.fbdata = querySnapshot.data()
              } else {
                console.log("cant get user data")
              }
            });
            


            firebase.firestore().collection('lines').onSnapshot(snapshot => {
              this.lines = [];
              this.options = [{ value: null, text: 'Select Service' }];
              snapshot.forEach(doc => {
                let data = doc.data()
                data.id = doc.id
                this.lines.push(data);
                this.options.push({value: doc.id, text: data.name})
              });
            });
            console.log('here are the lines:', this.lines)
      } else {
            console.log('No user')
            this.user = null
            this.$refs["modal"].show();
      }
    });
  },
  computed: {
    resultQuery() {
      if (this.searchQuery) {
        return this.postList.filter(item => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.title.toLowerCase().includes(v));
        });
      } else {
        return this.postList;
      }
    }


  },
  components: { Navbar, Footer },


};
</script>


<style >

.sq-input {
  border: 1px solid rgb(223, 223, 223);
  outline-offset: -2px;
  margin-bottom: 5px;
  display: inline-block;
  border: none;
  color: #32325d;
  line-height: 18px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  height: 18px;
  -webkit-font-smoothing: antialiased;
}

.sq-input ::placeholder {
  color: #aab7c4;
  opacity: 0.5;
}

/* Define how SqPaymentForm iframes should look when they have focus */

/* Define how SqPaymentForm iframes should look when they contain invalid values */

.sq-input--error {
  outline: 3px auto rgb(255, 97, 97);
}

.errorbox {
  line-height: 14px;
  text-align: left;
}

.error {
  font-size: 10px;
  color: rgb(164, 0, 30);
  width: 45%;
  display: inline-block;

  margin-top: -10px;
  font-weight: 400;
}

/* Customize the "Pay with Credit Card" button */

.button-credit-card {
  min-width: 200px;
  min-height: 20px;
  padding: 0;
  margin: 5px;
  line-height: 20px;
  box-shadow: 2px 2px 1px rgb(200, 200, 200);
  background: rgb(255, 255, 255);
  border-radius: 5px;
  border: 1px solid rgb(200, 200, 200);
  font-weight: bold;
  cursor: pointer;
}

.card-number {
  width: 100%;
}

.modal .payButton {
  margin-left: 0px;
  position: absolute;
  bottom: 0px;
  width: 400px;
}

/* Customize the "{{Wallet}} not enabled" message */

.wallet-not-enabled {
  min-width: 200px;
  min-height: 40px;
  max-height: 64px;
  padding: 0;
  margin: 10px;
  line-height: 40px;
  background: #eee;
  border-radius: 5px;
  font-weight: lighter;
  font-style: italic;
  font-family: inherit;
  display: block;
}

/* Customize the Apple Pay on the Web button */

.button-apple-pay {
  min-width: 200px;
  min-height: 40px;
  max-height: 64px;
  padding: 0;
  margin: 10px;
  background-image: -webkit-named-image(apple-pay-logo-white);
  background-color: black;
  background-size: 100% 60%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 5px;
  cursor: pointer;
  display: none;
}

/* Customize the Masterpass button */

.button-masterpass {
  min-width: 200px;
  min-height: 40px;
  max-height: 40px;
  padding: 0;
  margin: 10px;
  background-image: url(https://static.masterpass.com/dyn/img/btn/global/mp_chk_btn_147x034px.svg);
  background-color: black;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 5px;
  border-color: rgb(255, 255, 255);
  cursor: pointer;
}

#sq-walletbox {
  text-align: center;
  vertical-align: top;
  font-weight: bold;
}

#sq-ccbox {
  margin: 5px;
  padding: 0px 10px;
  text-align: center;
  vertical-align: top;
  font-weight: bold;
}

.expiration-date,
.cvv,
.postal-code {
  width: 30%;
  display: inline-block;
}

#card-tainer {
  text-align: left;
  margin-top: 8px;
  background-color: white;
  height: 80px;
  padding: 10px 12px;
  border-radius: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  box-sizing: border-box;
}
</style>
